import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/antonio-escritorio/Documents/moare/sites-estaticos/libres-de-violencias/src/components/default-mdx-layout.js";
import SpeakerSingle from "../../src/components/speaker-single";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SpeakerSingle name="Silvia Aquiles" profession="" presentation="Falso SAP (Síndrome de Alienación Parental) y sus consecuencias institucionales y jurídicas para madres e hijos víctimas de malos tratos." youtubeid="JFxwuiWDmC4" haspresentacion="true" hasspeach="true" skipTo={{
      h: 0,
      m: 58,
      s: 13
    }} mdxType="SpeakerSingle" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      